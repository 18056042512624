.main_device_left {
  margin-top: 104vh;
}
.notification-icon-background {
  position: fixed;
  float: right;
  right: 50px;
  background: #269961;
  backdrop-filter: blur(6px);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin: 15px 0px;
  cursor: pointer;
  z-index: 99999;
  bottom: 43px;

}

.bell{

  animation-name: col;

  animation-duration: 2s;

  animation-iteration-count: infinite;
}
.notification-icon-background img {
  position: absolute;
  left: 17px;
  top: 16px;
  width: 27px;

}

.bell-icon{
  left: 30px !important;
  top: 30px !important;
  animation-name: bell-ring;

    animation-duration: 2s;

    animation-iteration-count: infinite;
}

.notification-count {
  color: white;
  background-color: red;
  width: 16px;
  padding: 1px 2.5px;
  height: 16px;
  border-radius: 50%;
  font-size: 9px;
  margin: 0px auto;
  position: absolute;
  right: 16px;
  bottom: 29px;
  text-align: center;
}
.heartbeat {
  height: 47px;
  width: 47px;
  position: absolute;
  border: 1.5px solid white !important;
  animation-name: bord-pop;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  box-shadow: 2px 2px 5px #ccc, -2px -2px 5px #ccc;
  top: 30px;
  left: 30px;
  border-radius: 50px;
}
@keyframes bord-pop {

  0% {

      transform: translate(-50%, -50%);

  }

  50% {

      transform: translate(-50%, -50%) scale(1.9);

      opacity: 0.1;

  }

  100% {

      transform: translate(-50%, -50%) scale(1.9);

      opacity: 0;

  }

}

@keyframes col {

  0% {

      transform: scale(1) translate(0,0);

  }

  10% {

      transform: scale(1.1) translate(0,0);

  }

  75% {

      transform: scale(1) translate(0,0);

  }

  100% {

      transform: scale(1) translate(0,0);

  }

}

@keyframes bell-ring {

  0% {

      transform: translate(-50%, -50%);

  }

  5%, 15% {

      transform: translate(-50%, -50%) rotate(25deg);

  }

  10%, 20% {

      transform: translate(-50%, -50%) rotate(-25deg);

  }

  25%  {

      transform: translate(-50%, -50%) rotate(0deg);

  }

  100% {

      transform: translate(-50%, -50%) rotate(0deg);

  }

}