/*====================================== fixed-strip -----------------*/
/* .fix_strip {
  position: fixed;
  right: 10px;
  bottom: 286px;
  z-index: 1;
} */
.fix_strip .card {
  padding: 5px;
  margin: 1%;
  border: 2px solid #61ffa8;
}

.side-box label {
  font-size: 14px;
  font-weight: 600;
}
.side-box .input-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side-box .search-field {
  flex: 0.7;
  display: flex;
  align-items: center;
  margin: 3% 0%;
}
.side-box .search-field input {
  margin-left: 3%;
  border-right: 0px;
  margin-bottom: 0px !important;
  padding: 0.37rem;
  border-radius: 0px;
}
.side-box .search-field input:focus {
  outline: none;
}
.side-box .search-field i {
  padding: 7px;
  border: 1px solid #61ffa8;
  border-left: 0px !important;
  color: #61ffa8;
}
.side-box .fa-arrow-left {
  padding-right: 3%;
}
.brand-logos {
  height: 310px;
  margin-left: 3%;
  overflow-y: auto;
  overflow-x: hidden;
}
.brand-logos img {
  /* border: 1px solid #61ffa8; */
  margin: 3% 6%;
  margin-left: 0px !important;
  cursor: pointer;
}
.side-box .inside {
  color: black;
  font-size: 12px;
}
.side-box select,
.side-box input {
  width: 100%;
  border: 1px solid #61ffa8;
  margin-bottom: 5%;
}
/* switch toggle */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
  margin: 9px;
  margin-bottom: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #61ffa8;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 4px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
  border-radius: 3px;
}

input:checked + .slider {
  background-color: #52c944;
}

input:focus + .slider {
  box-shadow: 0 0 4px #7efa70;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
#round {
  border-radius: 34px;
}
#round:before {
  border-radius: 50%;
}

/*search-button*/
.search_main {
  border: 2px solid #047135 !important;
  background: #047135 !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 5px 23px;
  /* width: 50%; */
}

.search_main:hover {
  color: #047135 !important;
  background: white !important;
  /* box-shadow: 0px 5px 5px rgb(0 0 0 / 20%); */
  /* border: none; */
}

.each-modal,
.each-brand {
  display: inline-block;
  margin: 3% 2%;
  text-align: center;
  width: 28%;
  cursor: pointer;
  border-right: 2px solid #61ffa8;
  border-bottom: 2px solid #61ffa8;
  box-shadow: 0px 0px 4px #61ffa8;
  /* transition: 2s ease; */
}
.each-modal {
  height: 100px;
}
.each-year {
  width: 20% !important;
  text-align: center;
  padding: 1%;
  margin: 2%;
  /* background-color: #61ffa7; */
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid #047135 !important;
  color: #047135 !important;
  background: white !important;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
}
.each-year:hover {
  background: #047135 !important;
  border-radius: 10px !important;
  color: white !important;
}
.each-brand:hover,
.each-modal:hover {
  /* background: #61ffa8; */
  transform: scale(1.1, 1.1);
  z-index: 1;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  border: 2px solid #61ffa8;
}
.select-manufacturer .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-manufacturer .header i,
.select-model .header i,
.select-fueltype .header i,
.select-model-year ins {
  cursor: pointer;
}
.search-services .modal-dialog {
  float: right;
  margin-top: 12rem;
  margin-right: 1.5rem;
}

@media (max-width: 1920px) {
  /* .fix_strip {
    
    bottom:300px;
	} */
}
@media (min-width: 1367px) and (max-width: 1600px) {
  /* .number_input
	{
		display: none;
	} */
  .fix_strip {
    /* bottom: 150px; */
    position: sticky;
    float: right;
    /* top: 100px; */
    right: 10px;
  }
}

@media (max-width: 1366px) {
  /* .fix_strip {
    position: fixed;
    right: 10px;
    top: 80px;
    bottom: 200px;
    z-index: 1;
  } */

  .fix_strip .card {
    margin: 2%;
    padding: 10px;
  }
  .fix_strip .card-body {
    padding: 10px;
  }
  .fix_strip .card-body h4 {
    font-size: 16px;
  }

  .fix_strip .card-body p {
    font-size: 11px;
    margin-bottom: 10px;
  }
  /* .number_input
	{
		display: none;
	} */
  .switch {
    width: 50px;
    margin-bottom: 5px;
  }

  .side-box label {
    font-size: 12px;
  }
}

/* width */
.brand-logos::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.brand-logos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.brand-logos::-webkit-scrollbar-thumb {
  background: #67866c;
  border-radius: 10px;
}

/* Handle on hover */
.brand-logos::-webkit-scrollbar-thumb:hover {
  background: #67866c;
}
